<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<div class="list-box">
					<div class="tit">
                        공급기업 지정 프로젝트 > 전체 프로젝트
                        <p class="sub_tit"><span>{{prjList.length}}</span>개의 프로젝트가 있습니다!</p>
                    </div>
					<div class="search-box interval">
						<SelectComp type="select" title="승인 상태" list="1:미등록,2:등록" :isAll="true" v-model="srchFilter.projectCheckStatus" /><nbsp/>
                        <SelectComp type="select" title="프로젝트 상태" list="4:제안중,2:수행예정,1:수행중,9:완료" :isAll="true" v-model="srchFilter.projectStatus" /><nbsp/>
						<SelectComp type="select" title="프로젝트 상태" list="1:프로젝트명,2:수행사명" v-model="srchFilter.searchGubun" />
											
						<InputComp type="text" :placeholder="srchFilter.searchGubun ==  '1' ? '프로젝트명' : '수행사명'" v-model="srchFilter.searchKeyword" @keyup.enter="getDcoPgmPrjAppPrjList('1')" /><nbsp/>
						<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getDcoPgmPrjAppPrjList('1')"/>
					</div>
					<div class="filter">
						<div class="filter_box"> 
							<ul>
								<li @click="changeOrderGubun('1')"><img src="/images/icon_filter.png" alt="등록일 순" :class="srchFilter.orderGubun == '1' ? 'filter' : ''"/><p>등록일 순</p><span>ㅣ</span></li>
								<li @click="changeOrderGubun('2')"><img src="/images/icon_filter.png" alt="개발기간 긴 순" :class="srchFilter.orderGubun == '2' ? 'filter' : ''"/><p>개발기간 긴 순</p><span>ㅣ</span></li>
								<li @click="changeOrderGubun('3')"><img src="/images/icon_filter.png" alt="개발기간 짧은 순" :class="srchFilter.orderGubun == '3' ? 'filter' : ''"/><p>개발기간 짧은 순</p><span>ㅣ</span></li>
							</ul>
						</div>
                        <!-- <div class="prj-regist-btn">
                            <div>
                                <button type="button" @click="$router.push('PRJ301M01')">프로젝트 등록</button>
                            </div>
                        </div> -->
					</div>

					<div class="Board type3">
						<table class="Board_type3 prj list-all" style="margin-left: 0;">
							<colgroup>
                                <col width="3%">
                                <col width="*">
                                <col width="16%">
								<col width="16%">
                                <!-- <col width="10%"> -->
                                <col width="8%">
								<col width="10%">
                                <!-- <col width="4%"> -->
                                <!-- <col width="5%"> -->
                                <!-- <col width="12%"> -->
                            </colgroup>
							<thead>
								<tr v-if="prjList.length > 0">
									<th>NO</th>
									<th>프로젝트명</th>
									<th>프로젝트 기간</th>
									<th>수행사명</th>
									<!-- <th>등록일</th> -->
									<th>프로젝트 상태</th>
									<th>등록일</th>
                                    <!-- <th>지정<br/>기업</th> -->
                                    <!-- <th>등록<br/>기업</th> -->
									<!-- <th>공급기업별 프로젝트</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="(project, index) in prjList" :key="index">
									<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
									<td class="score prj-name">
                                        <div style="display: flex; justify-content: space-between; align-items: center; margin: 0 10px;">
                                            <div class="rc_tag" >
												<div v-if="project.projectSeq == undefined" class="rc_mark_red">미등록</div>
												<div v-else-if="project.recrEndDateDiff >= 0" class="rc_mark">모집중</div>
                                                <!-- <div v-else-if="project.projectStatus == 4" class="rc_mark_black">제안단계</div> -->
                                            </div>
                                            <div class="project">{{project.projectNm}}</div>
                                            <div v-if="project.projectSeq != undefined" class="btn" style="min-width: 110px;" @click="clickProjectDetail(project)">프로젝트 보기 &#9654;</div>
											<img v-else src="/images/project_btn_new.png" style="cursor:pointer" @click="goModifyProject(project)">
                                        </div>
									</td>
									<td class="score">{{project.projectStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{project.projectEndYyyymmdd | date('yyyy.MM.dd')}}</td>
									<td class="score">{{project.corpNm}}</td>
									<td class="score">                                        
										<span v-if="project.projectStatus == 1"><div class="state pf"></div>수 행 중</span>
										<span v-if="project.projectStatus == 4"><div class="state us"></div>제 안 중</span>
										<span v-if="project.projectStatus == 2"><div class="state ep"></div>수행예정</span>
										<span v-if="project.projectStatus == 9"><div class="state cp"></div>수행완료</span>
									</td>
									<td v-if="project.projectSeq != undefined" class="score">{{project.regDate | date('yyyy.MM.dd')}}</td>
									<td v-else class="score">-</td>
                                    <!-- <td class="score">{{project.totCnt}}</td> -->
                                    <!-- <td class="score">{{project.prjCnt}}</td> -->
									<!-- <td class="score resume">
										<div class="btn" v-if="project.apprYn != 'N'" style="min-width: 110px;" @click="clickProjectDetail(project)">리스트 보기 &#9654;</div>
										<div v-else style="min-width: 110px;">-</div>
									</td> -->
								</tr>
								
								<!-- 등록된 프로젝트가 없을 경우 -->
								<tr v-if="prjList.length <= 0">
									<td colspan="7" class="none">프로젝트가 없습니다!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- 페이징부분 -->
					<pagingComp :pageInfo="pageInfo" @page-click="goPage" />
				</div>
			</div>
			<!-- footer -->
			<!-- <footer-layout type="left"/> -->
		</div>
	</div>	
</template>

<script>
// import gradeImg from "@/components/highpro/GradeImg.vue"
import pagingComp from '@/components/PagingComp.vue';
// import tecProfile from "@/components/highpro/TecProfile.vue";
// import footerLayout from "@/components/layout/footer.vue";
// import fixTop from "@/components/FixTop.vue";

export default {
	data() {
		return {
			pageInfo : {},
			corpdiv : this.$store.state.userInfo.mberDivCd,

			srchFilter : {
				searchKeyword : '',
				orderGubun : 1, 
				pageIndex : 1,
				searchGubun : 1,
			},

			
			prjList :[],

		
			nowYyyymmdd : new Date().format("yyyyMMdd"),
		}
	},

	components : {
		pagingComp
	},

	beforeMount() {
	
		this.getDcoPgmPrjAppPrjList();
	},

	mounted(){
        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu':false})
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		getDcoPgmPrjAppPrjList(div) {
			var param = this.srchFilter;
			param.pageUnit = 20;
			param.pageSize = 10;
			param.corpdiv = this.corpdiv;
			if(div) this.srchFilter.pageIndex = 1;

			this.$.httpPost('/api/prj/sco/choice/getScoMyProjectList', param)
				.then((res) => {
					// console.log('getParentProjectList RESULT', res.data);
					this.prjList = res.data.prjList;
					this.pageInfo = res.data.pageInfo;
				});
		},

	

		// 정렬 순서
		changeOrderGubun(orderGubun) {
			this.srchFilter.orderGubun = orderGubun;
			this.srchFilter.pageIndex = 1;
			this.getDcoPgmPrjAppPrjList();
		},

		// 페이지 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getDcoPgmPrjAppPrjList();
		},

		// 프로젝트 관리
		goModifyProject(project) {
			if(project.projectSeq == undefined) {
				this.$router.push({name : 'PRJ406M02', params : {parentProjectSeq : project.parentProjectSeq ,projectNm : project.projectNm, corpNm : project.corpNm, caller : { name : this.$route.name, params :this.input}}});
			} else {
				this.$router.push({name : 'PRJ406M02', query : {projectSeq : project.projectSeq}, params : {projectNm : project.projectNm, corpNm : project.corpNm, caller : { name : this.$route.name, params :this.input}}});
			}

			
		},

		// 자식프로젝트 등록 보기
		clickProjectDetail(project) {
			// console.log(project);
			if(project.recrEndDateDiff >= 100000) {
                this.$router.push({name : 'PRJ407M01', params : { srchName : project.projectNm, caller : { name : this.$route.name, params :this.input}}});
            } else {
                switch(project.projectStatus) {
                    case '4' : this.$router.push({name : 'PRJ407M02', params : { srchName : project.projectNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '2' : this.$router.push({name : 'PRJ407M03', params : { srchName : project.projectNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '1' : this.$router.push({name : 'PRJ407M04', params : { srchName : project.projectNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '9' : this.$router.push({name : 'PRJ407M05', params : { srchName : project.projectNm, caller : { name : this.$route.name, params :this.input}}}); break;
                        
                }
            }	
		},

	}
}
</script>